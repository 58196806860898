import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@material-ui/core";
import { useState } from "react";
import { nanoid } from "nanoid";

const DropActionMenu = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e, item) => {
    if (item.onClick) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      item.onClick(e);
    }
    return handleClose();
  };

  const elemId = `menu-${nanoid()}`;

  return (
    <>
      <button
        className="o-button o-button--simple"
        aria-controls={elemId}
        aria-haspopup="true"
        onClick={handleClick}
        aria-label="open actions menu"
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      <Menu
        id={elemId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((item, idx) => (
          <MenuItem
            key={idx}
            onClick={(e) => handleMenuItemClick(e, item)}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropActionMenu;
