import constants from "../../constants";

const setStateMakeApiRequest = () => {
  return {
    type: constants.reduxActions.API_REQUEST_IN_PROGRESS,
  };
};

const setStateEndApiRequestSuccess = () => {
  return {
    type: constants.reduxActions.API_REQUEST_SUCCESS,
  };
};

const setStateEndApiRequestFailure = (errorMessage = null) => {
  return {
    type: constants.reduxActions.API_REQUEST_FAILURE,
    message: errorMessage,
  };
};

const apiActions = {
  setStateMakeApiRequest,
  setStateEndApiRequestSuccess,
  setStateEndApiRequestFailure
}

export default apiActions;