import { useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";
import AlpsContentContainer from "../components/shared/AlpsContentContainer";
import DisplayMessageCard from "../components/shared/DisplayMessageCard";

const PageReserved = () => {
  const [redirectTo, setRedirectTo] = useState(null);
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return AlpsContentContainer(
    <DisplayMessageCard
      message="Page access is restricted"
      actionTitle="Go Home"
      action={() => setRedirectTo("/")}
    />
  );
};

const AdminRoute = ({ component: Component, userStatus, ...rest }) => {
  return userStatus.user.category === "admin" ? (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  ) : (
    <Route {...rest} component={PageReserved} />
  );
};

function mapStateToProps(state) {
  const appState = {
    userStatus: state.user,
  };
  return appState;
}

export default connect(mapStateToProps)(AdminRoute);
