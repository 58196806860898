import constants from "../../constants";

export default function userReducer(
  state = { loggedIn: false, token: null, authErrorMessage: null },
  action
) {
  switch (action.type) {
    case constants.reduxActions.LOGIN_USER:
      const { data } = action;
      return {
        loggedIn: true,
        token: data.access_token,
        user: data.user,
        errorMessage: null,
      };
    case constants.reduxActions.UPDATE_CURRENT_USER_PROFILE:
      return { ...state, user: action.user };
    case constants.reduxActions.LOAD_ALL_USERS_SUCCESS:
      return { ...state, users: action.users };
    case constants.reduxActions.DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.userId),
      };
    case constants.reduxActions.BATCH_DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => !action.userIds.includes(user.id)),
      };
    case constants.reduxActions.BLOCK_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => {
          return {
            ...user,
            blocked: user.id === action.userId ? 1 : user.blocked,
          };
        }),
      };
      case constants.reduxActions.VERIFY_USER_SUCCESS:
        return {
          ...state,
          users: state.users.map((user) => {
            return {
              ...user,
              email_verified_at: user.id === action.userId ? '2018-01-01 00:00:00' : user.email_verified_at,
            };
          }),
        };
    case constants.reduxActions.UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => {
          return {
            ...user,
            blocked: user.id === action.userId ? 0 : user.blocked,
          };
        }),
      };
    case constants.reduxActions.LOGIN_ERROR:
      return {
        ...state,
        loggedIn: false,
        authErrorMessage: action.errorMessage,
      };
    case constants.reduxActions.LOGOUT_USER:
      return { loggedIn: false, token: null, user: {}, errorMessage: null };
    default:
      return state;
  }
}
