import {
  faFileDownload,
  faInfoCircle,
  faPencilAlt,
  faPlusCircle,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { connect } from "react-redux";

import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
} from "react-table";
import { bindActionCreators } from "redux";
import recordActions from "../../../redux/actions/recordsActions";
import {
  generateSelectionColumn,
  showModalDialog,
  uiTransformers,
  generateTableHeader,
} from "../../../utils/sharedUtils";
import ConfirmationDialog from "../../shared/ConfirmationDialog";
import DataTableSearchBox from "../../shared/DataTableSearchBox";
import apiService from "../../../services/api/apiService";
import { useToasts } from "react-toast-notifications";
import RecordDetails from "../record-details/RecordDetails";
import AlpsContentContainer from "../../shared/AlpsContentContainer";
import DropActionMenu from "../../shared/DropActionMenu";
import DataTablePageSelectorDropdown from "../../shared/DataTablePageSelectorDropdown";
import DataTablePageNavigatorButtons from "../../shared/DataTablePageNavigatorButtons";
import constants from "../../../constants";

const RecordsPage = (props) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const recordsData = props.records;
  const data = useMemo(() => recordsData, [recordsData]);
  const columns = useMemo(
    () => [
      {
        Header: "Date Insured",
        accessor: "date_insured", // accessor is the "key" in the data
        Footer: "Totals",
      },
      {
        Header: "Church/Entity",
        accessor: "entity_name",
      },
      {
        Header: "Erf nr",
        accessor: "erf",
        disableSortBy: true,
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Description",
        accessor: "description",
        disableSortBy: true,
      },
      {
        Header: "Bldg Value",
        accessor: "building_value_formatted",
        Footer: uiTransformers.formatMoney(
          data.reduce(
            (total, { building_value }) =>
              (total += parseFloat(building_value)),
            0
          )
        ),

        sortType: "basic",
      },
      {
        Header: "Contents Value",
        accessor: "contents_value_formatted",
        Footer: uiTransformers.formatMoney(
          data.reduce(
            (total, { contents_value }) =>
              (total += parseFloat(contents_value)),
            0
          )
        ),

        sortType: "basic",
      },
      {
        Header: "Total Value",
        accessor: "total_value_formatted",
        Footer: uiTransformers.formatMoney(
          data.reduce(
            (total, { total_value }) => (total += parseFloat(total_value)),
            0
          )
        ),

        sortType: "basic",
      },
      {
        Header: "Premium",
        accessor: "premium",
        Footer: uiTransformers.formatMoney(
          data.reduce((total, { premium }) => (total += parseFloat(premium)), 0)
        ),
      },
    ],
    [data]
  );

  useEffect(() => {
    // if (!props.records || props.records.length < 1) {
    props.recordActions.loadRecords();
    // }
  }, []);

  const deleteRow = (recordId) => {
    props.recordActions
      .deleteRecord(recordId)
      .then(() => {
        addToast("Deleted!", { appearance: "success" });
      })
      .catch((error) => {
        addToast(apiService.responseHandler.describeError(error), {
          appearance: "error",
        });
      });
  };

  const batchDelete = (selectedEntryIds) => {
    const count = selectedEntryIds.length;
    const warningMsg = `${count} entries will be permanently deleted. This is irreversible`;
    showModalDialog(
      <ConfirmationDialog
        message={warningMsg}
        successBtnCallback={() => {
          props.recordActions
            .batchDeleteRecords(selectedEntryIds)
            .then(() => {
              addToast("Deleted!", { appearance: "success" });
            })
            .catch((error) => {
              addToast(apiService.responseHandler.describeError(error), {
                appearance: "error",
              });
            });
        }}
        cancelBtnCallback={null}
      />,
      "Warning"
    );
  };

  const viewDetails = (e, details) => {
    e.preventDefault();
    showModalDialog(<RecordDetails data={details} />, "Details");
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    //  rows,
    prepareRow,
    pageOptions,
    page,
    preGlobalFilteredRows,
    setGlobalFilter,
    // gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    canPreviousPage,
    canNextPage,
  } = useTable(
    { columns, data },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        generateSelectionColumn(),
        ...columns,
        {
          id: "actions",
          Header: "Actions",
          Cell: ({ row }) => {
            // console.log(row);
            return (
              <>
                <DropActionMenu
                  menuItems={[
                    {
                      title: (
                        <>
                          <FontAwesomeIcon icon={faInfoCircle} />
                          &nbsp; View Details...
                        </>
                      ),
                      onClick: (e) => viewDetails(e, row.original),
                    },
                    {
                      title: (
                        <>
                          <FontAwesomeIcon icon={faPencilAlt} />
                          &nbsp; Edit
                        </>
                      ),
                      onClick: () =>
                        history.push(
                          `/records/edit/${
                            row.original.id ? row.original.id : ""
                          }`
                        ),
                    },
                    {
                      title: (
                        <>
                          <FontAwesomeIcon icon={faTrash} />
                          &nbsp; Delete
                        </>
                      ),
                      onClick: (e) => {
                        e.preventDefault();
                        // console.log(row.id);
                        showModalDialog(
                          <ConfirmationDialog
                            message="This is permanent and cannot be undone"
                            successBtnCallback={() => {
                              console.log("init delete", row.original.id);
                              deleteRow(row.original.id);
                            }}
                            cancelBtnCallback={null}
                          />,
                          "Warning"
                        );
                      },
                    },
                  ]}
                />
              </>
            );
          },
        },
      ]);
    }
  );

  const getColumnAlignment = (id) => {
    if (id.includes("value") || id === "premium" || id === "actions") {
      return "text-right";
    }
    return "";
  };

  return AlpsContentContainer(
    <>
      <div className="sg-pattern-example">
        <div className="c-page-header__search ">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="input-group-ctl-parent">
                <div className="input-group-ctl">
                  <DataTableSearchBox
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </div>
                <div className="input-group-ctl-prepend">
                  <button className="o-button o-button--outline">
                    <span className="u-icon">
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 text-right  whitespace-nowrap">
              <a
                href={`${constants.envConstants.BASE_API_URL}/../export/records`}
                target="_blank"
                rel="noreferrer"
                className="o-button o-button--outline u-space--right"
              >
                <span className="u-icon">
                  <FontAwesomeIcon icon={faFileDownload} />
                </span>
                Download
              </a>
              {Object.keys(selectedRowIds).length < 1 && (
                <a className="o-button" href="/records/edit/new">
                  <span className="u-icon">
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </span>
                  Add New
                </a>
              )}
              {Object.keys(selectedRowIds).length > 0 && (
                <button
                  onClick={() =>
                    batchDelete(selectedFlatRows.map((row) => row.original.id))
                  }
                  className="o-button alps-bg-scarlett"
                >
                  <span className="u-icon">
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                  Delete Sel.
                </button>
              )}
            </div>
          </div>
          <div className="c-filter c-filter-is-active"></div>
        </div>
      </div>
      <br />

      <div
        className="table-responsive"
        style={{ border: "1.5px solid #717171" }}
      >
        <table {...getTableProps()} className=" o-table-slim">
          <thead>{generateTableHeader(headerGroups, true)}</thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`data-table-row ${
                    Object.keys(selectedRowIds).includes(row.id)
                      ? "selected"
                      : ""
                  }`}
                  onClick={(e) => viewDetails(e, row.original)}
                >
                  {row.cells.map((cell) => {
                    // console.log(cell.column.id);
                    return (
                      <td
                        {...cell.getCellProps()}
                        title={
                          cell.column.id === "description"
                            ? row.original.full_description
                            : cell.value
                        }
                        className={getColumnAlignment(cell.column.id)}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td
                    {...column.getFooterProps()}
                    style={{
                      fontWeight: "bold",
                      fontSize: "0.8em",
                      padding: 0,
                    }}
                    className={getColumnAlignment(column.id)}
                  >
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>

      <br />
      <div className="row">
        <div className="col-12 col-md-4">
          <DataTablePageNavigatorButtons
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            onClickNextPage={nextPage}
            onClickPreviousPage={previousPage}
          />
        </div>

        <div className="col-6 col-md-4 text-center">
          <span className="text-small">
            Page{" "}
            <b>
              {pageIndex + 1} of {pageOptions.length}
            </b>
          </span>
        </div>
        <div className="col-6 col-md-4 text-right">
          <DataTablePageSelectorDropdown
            pageSizeOptions={[10, 20, 50, 100, 500]}
            currentPageSize={pageSize}
            onChangePageSize={setPageSize}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const records = state.recordsState.records
    ? uiTransformers.mapRecordsForDisplay(state.recordsState.records)
    : [];
  return {
    records,
  };
};

const mapDispathToProps = (dispatch) => {
  return {
    recordActions: bindActionCreators(recordActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispathToProps)(RecordsPage);
