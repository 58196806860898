export const ERROR_OCCURED = "An error occured";
export const NETWORK_ERROR = "Poor or no internet connection";
export const ENTER_YOUR_EMAIL = "Enter your email";
export const INSTRUCTIONS_WILL_BE_SENT =
  "An email with instructions to reset your account password will sent here";
export const ENTER_NEW_PASSWORD = "Enter new password";
export const REENTER_NEW_PASSWORD = "Confirm password";
export const REGISTRATION_SUCCESS =
  "Thank you! You have successfully registered. An email has been sent to the provided email address for confirmation.";
export const FIELD_REQUIRED = "This is required";
export const FIELD_VALUE_SHORT = "Value is too short";
export const FIELD_VALUE_LONG = "Value is too long";
export const FIELD_VALUE_MIN = "Value is below minimum";
export const FIELD_VALUE_MAX = "Value is above maximum";
export const FIELD_INVALID_EMAIL = "Value must be a valid email";
export const FIELD_INVALID_PHONE = "Phone number is not valid";
export const FIELD_PASSWORD_MUST_MATCH = "Passwords must match";
export const FORM_CHECK_REQUIRED_FIELDS =
  "Please make sure all fields are filled properly";
export const SET_NEW_PASSWORD_SUCCESS =
  "Your new password has been set successfully";
export const RESEND_FORGOT_SUCCESS =
  "An email with instructions to reset your account has been sent";
export const VERIFICATION_SUCCESS =
  "Thank you! You have successfully verified your account.";
export const RESEND_ACTIVATION_SUCCESS =
  "An email with the activation code was sent to the provided email. Please note it may take some minutes for the email to arrive and the email could also land in your Spam folder.";
export const SAVED_SUCCESS = "Saved successfully";
export const FILL_ALL_FIELDS = "Please make sure all the required information is provided";