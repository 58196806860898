import DisplayMessageCard from "./DisplayMessageCard";
import AlpsContentContainer from "./AlpsContentContainer";

const ConnectionErrorCard = () => {
  return (
    AlpsContentContainer(<DisplayMessageCard
      message="An error occured connecting to the service"
      actionTitle="Try Again"
      action={() => window.location.reload()}
      
    />)
  );
};

export default ConnectionErrorCard;
