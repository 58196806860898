import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";

function configureStore(initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // support for redux devtools
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant()))
  );
}

// set initialState / load state and details like login based on data retrieved from localStorage.

const savedToken = localStorage.getItem("token");

const savedUserData = localStorage.getItem("user");

let userToken;
try {
  if (savedToken) {
    userToken = JSON.parse(savedToken);
  }
} catch (e) {
  // console.log("could not parse saved user token");
}

let userData;
try {
  if (savedUserData) {
    userData = JSON.parse(savedUserData);
  }
} catch (e) {
  // console.log("could not parse saved user data");
}

const userState = {
  loggedIn: userToken ? true : false,
  token: userToken ? userToken : null,
  user: userData ? userData : {},
};

const store = configureStore({ user: userState });

export {store};
