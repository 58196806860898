import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import constants from "../../constants";
import apiService from "../../services/api/apiService";
import AlpsLayoutContainer from "../shared/AlpsLayoutContainer";
import ArrowRight from "../shared/ArrowRight";
import logo from "../../assets/logo.svg";
import StyledErrorMessage from "../shared/StyledErrorMessage";
import { setFormInputStyle } from "../../utils/sharedUtils";

const SetNewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(7, "Must be at least 7 characters")
    .max(40, constants.messages.FIELD_VALUE_LONG)
    .required(constants.messages.FIELD_REQUIRED),
  passwordConfirm: Yup.string()
    .required(constants.messages.FIELD_REQUIRED)
    .test(
      "passwords-match",
      constants.messages.FIELD_PASSWORD_MUST_MATCH,
      function (value) {
        return this.parent.password === value;
      }
    ),
  email: Yup.string()
    .email(constants.messages.FIELD_INVALID_EMAIL)
    .required(constants.messages.FIELD_REQUIRED),
});

const SetNewPassword = (props) => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const [resetPasswordStatus, setResetPasswordStatus] = useState({
    attempted: false,
    success: false,
    errorMessage: null,
  });

  const handleSubmit = (values) => {
    setResetPasswordStatus({
      success: false,
      errorMessage: null,
    });
    apiService
      .post(`${constants.envConstants.BASE_API_URL}/user/reset-password`, {
        email: values.email,
        password: values.password,
        password_confirmation: values.passwordConfirm,
        token,
      })
      .then((result) => {
        setResetPasswordStatus({
          attempted: true,
          success: true,
          errorMessage: null,
        });
      })
      .catch((error) => {
        setResetPasswordStatus({
          attempted: true,
          success: false,
          errorMessage: apiService.responseHandler.describeError(error),
        });
      });
  };

  return AlpsLayoutContainer(
    <div>
      <div className="text u-spacing u-space--top 1-grid">
        <img src={logo} alt="Cape Conference Logo" className="loginLogo" />
        <Formik
          initialValues={{
            email: "",
            password: "",
            passwordConfirm: "",
          }}
          validationSchema={SetNewPasswordSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form style={{ width: "100%" }} autoComplete="off">
              <h2 className="u-font--secondary--l u-theme--color--darker">
                Set New Password
              </h2>
              {!resetPasswordStatus.success && (
                <>
                  <label>
                    {constants.messages.ENTER_YOUR_EMAIL}
                    <Field
                      type="email"
                      name="email"
                      className={setFormInputStyle(errors, touched, "email")}
                    />
                  </label>

                  <StyledErrorMessage name="email" />
                  <br />
                  <br />
                  <label>
                    {constants.messages.ENTER_NEW_PASSWORD}
                    <Field
                      type="password"
                      name="password"
                      className={setFormInputStyle(errors, touched, "password")}
                    />
                  </label>

                  <StyledErrorMessage name="password" />
                  <br />
                  <label>
                    {constants.messages.REENTER_NEW_PASSWORD}
                    <Field
                      type="password"
                      name="passwordConfirm"
                      className={setFormInputStyle(
                        errors,
                        touched,
                        "passwordConfirm"
                      )}
                    />
                  </label>

                  <StyledErrorMessage name="passwordConfirm" />
                  <br />
                  <br />
                  {resetPasswordStatus.errorMessage && (
                    <>
                      <div className="alert alert-danger" role="alert">
                        {resetPasswordStatus.errorMessage}
                      </div>
                      <br />
                    </>
                  )}

                  <div className="u-flex u-flex--align-center">
                    <button
                      type="submit"
                      className={`o-button ${
                        Object.values(errors).length > 0 ? "disabled" : ""
                      }`}
                      disabled={Object.values(errors).length > 0}
                    >
                      Save{" "}
                    </button>{" "}
                    &nbsp;{" "}
                    <Link to="/logout" className="o-button o-button--outline">
                      Cancel
                    </Link>
                  </div>
                </>
              )}
              {resetPasswordStatus.success && (
                <>
                  <div className="alert alert-success" role="alert">
                    {constants.messages.SET_NEW_PASSWORD_SUCCESS}
                  </div>
                  <br />
                  <Link to="/logout" className="o-button">
                    Go to Login <ArrowRight />
                  </Link>
                  <br />
                  <br />
                  <br />
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SetNewPassword;
