import { DialogActions, DialogContent } from "@material-ui/core";
import { closeModalDialog, uiTransformers } from "../../../utils/sharedUtils";

const RecordDetails = (props) => {
  const { data } = props;
  return (
    <>
      <DialogContent dividers>
        <div className="row" style={{ minWidth: "30vw" }}>
          <div className="col col-12">
            <p>
              <b>Date Insured</b>: {data.date_insured}
            </p>
            <p>
              <b>Church/Entity</b>: {data.entity_name}
            </p>
            <p>
              <b>Address</b>: {data.address}
            </p>
            <p>
              <b>Type</b>: {data.type}
            </p>
            <p>
              <b>Erf nr</b>: {data.erf}
            </p>
            <p>
              <b>Description</b>: {data.full_description.split("\n").map((item, idx) => <span key={idx}>{item}<br/></span>)}
            </p>
            <p>
              <b>Serial No.</b>: {data.serial}
            </p>
            <p>
              <b>Building Value</b>:{" "}
              {data.building_value > 0
                ? uiTransformers.formatMoney(data.building_value, true)
                : ""}
            </p>
            <p>
              <b>Contents Value</b>:{" "}
              {data.contents_value > 0
                ? uiTransformers.formatMoney(data.contents_value, true)
                : ""}
            </p>
            <hr />
            <p>
              <b>Total Value</b>:{" "}
              {uiTransformers.formatMoney(data.total_value, true)}
            </p>

            <p>
              <b>Premium</b>: {uiTransformers.formatMoney(data.premium, true)}
            </p>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          onClick={() => closeModalDialog()}
          className="o-button"
        >
          Close
        </button>
      </DialogActions>
    </>
  );
};

export default RecordDetails;
