import React, { useEffect, useState, Suspense, lazy } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PageNotFound from "../page-not-found/PageNotFound";
import Aside from "../shared/Aside";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import "./HomePage.css";
import modalActions from "../../redux/actions/modalActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

import LoadingIndicator from "../shared/LoadingIndicator";
import apiService from "../../services/api/apiService";
import constants from "../../constants";
import ConnectionErrorCard from "../shared/ConnectionErrorCard";
import { Dialog, DialogTitle } from "@material-ui/core";
import RecordsPage from "./records/RecordsPage";
import AdminRoute from "../../utils/AdminRoute";

// lazy load
const EditRecord = lazy(() => import("../edit-record/EditRecord"));
const EditEntity = lazy(() => import("../edit-entity/EditEntity"));
const UserProfilePage = lazy(() =>
  import("./user-profile-page/UserProfilePage")
);
const DuplicateEntriesPage = lazy(() =>
  import("./duplicate-entries/DuplicateEntriesPage")
);
const SettingsPage = lazy(() => import("./settings/SettingsPage"));
const UsersPage = lazy(() => import("./users/UsersPage"));
const EntitiesPage = lazy(() => import("./entities/EntitiesPage"));

function HomePage(props) {
  const { isOpen } = props.modalStatus;
  const { closeModal } = props.modalActions;
  const history = useHistory();
  const [isConnected, setIsConnected] = useState(true);


  useEffect(() => {
    // check just once if user's auth credentials are accepted by requesting user's own profile
    const ensureLoggedIn = () => {
      if (props.userStatus.loggedIn) {
        apiService
          .get(`${constants.envConstants.BASE_API_URL}/auth`)
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              // unauthorized, so navigate to login page.
              history.push("/logout");
            }
            if (!error.response) {
              setIsConnected(false);
            }
          });
      }
    };
    ensureLoggedIn();
  }, []);

  return props.userStatus.loggedIn ? (
    <div className="l-wrap">
      <div className="l-wrap__content l-content" role="document">
        <Header />
        <main
          className="l-main u-spacing--double u-padding--double--bottom u-background-color--white"
          role="main"
        >
          {isConnected && (
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route path="/records/edit/:id" component={EditRecord} exact />
                <AdminRoute
                  path="/records/duplicates"
                  component={DuplicateEntriesPage}
                  exact
                />
                <Route path="/entities/edit/:id" component={EditEntity} exact />
                <AdminRoute path="/users" component={UsersPage} exact />
                <Route path="/home" component={RecordsPage} />
                <Route path="/profile" component={UserProfilePage} exact />
                <Route path="/records" component={RecordsPage} exact />
                <AdminRoute path="/settings" component={SettingsPage} exact />
                <AdminRoute path="/entities" component={EntitiesPage} exact />
                <Route path="/" component={RecordsPage} exact />
                <Route component={PageNotFound} />
              </Switch>
            </Suspense>
          )}
          {!isConnected && <ConnectionErrorCard />}
        </main>

        <Footer />
      </div>
      <Aside />

      <Dialog
        onClose={closeModal}
        aria-labelledby="global-dialog-title"
        open={isOpen}
      >
        <DialogTitle
          id="global-dialog-title"
          onClose={closeModal}
          disableTypography
        >
          <span className="title">
            <h2>
              <FontAwesomeIcon icon={faInfoCircle} />
              &nbsp;
              {props.modalStatus.title ? props.modalStatus.title : "Info"}
            </h2>
          </span>
          <span className="close-button">
            <button className="o-button o-button--simple" onClick={closeModal}>
              <h2>
                <FontAwesomeIcon icon={faTimes} />
              </h2>
            </button>
          </span>
        </DialogTitle>
        {props.modalStatus.content}
      </Dialog>
      {props.apiStatus.isWorking && <LoadingIndicator horizontal={true} />}
    </div>
  ) : (
    <Redirect to="/login" />
  );
}

function mapStateToProps(state) {
  const appState = {
    userStatus: state.user,
    apiStatus: state.api,
    modalStatus: state.modal,
  };
  return appState;
}

function mapDispathToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispathToProps)(HomePage);
