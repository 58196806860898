import { createMuiTheme } from "@material-ui/core";

const alpsifiedMaterial = createMuiTheme({
  palette: {
    primary: { main: "#2e6de7" },
    secondary: { main: "#052252" },
  },
  shape: {
    borderRadius: 0,
  }, 
});

export default alpsifiedMaterial;
