import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DataTablePageNavigatorButtons = ({
  canPreviousPage,
  onClickPreviousPage,
  onClickNextPage,
  canNextPage,
}) => {
  return (
    <>
      <button
        className={`o-button o-button--small o-button--simple ${
          !canPreviousPage ? "disabled" : ""
        }`}
        onClick={onClickPreviousPage}
        disabled={!canPreviousPage}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        &nbsp; Prev Page
      </button>
      <button
        className={`o-button o-button--small o-button--simple ${
          !canNextPage ? "disabled" : ""
        }`}
        onClick={onClickNextPage}
        disabled={!canNextPage}
      >
        Next Page &nbsp; <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </>
  );
};
export default DataTablePageNavigatorButtons;
