const DisplayMessageCard = ({message, action, actionTitle}) => {
    const errorMsgStyle = {
      marginTop: "2em",
      padding: "2em",
      backgroundColor: "#fff",
      display: "block",
      width: "100%",
      border: "1px solid #aaa",
    };
  
    return (
      <div style={errorMsgStyle}>
        <p className="u-font--secondary--s">{message}</p>
        {action && <p>
          <button
            type="button"
            className="o-button"
            onClick={action}
          >
            {actionTitle}
          </button>
        </p>}
      </div>
    );
  };
  
  export default DisplayMessageCard;
  