import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faCog, faUser } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation } from "react-router-dom";

import logo from "../../assets/logo.svg";
import HeaderMenu from "./HeaderMenu";
import DrawerMenu from "./DrawerMenu";
import { useEffect, useState } from "react";

const Header = (props) => {
  const activeUser = props.userStatus.user;
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  const closeDrawer = () => {
    setIsDrawerOpen(false);
  }
  const handleMenuClick = (e) => {
    e.preventDefault();
    toggleDrawer();
  };

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location])

  return (
    <>
      <header
        className="c-header u-background-color--near-white"
        role="banner"
        id="header"
      >
        <div className="c-header--inner">
          <div className="c-header__nav-secondary">
            <nav className="c-secondary-nav" role="navigation">
              <ul className="c-secondary-nav__list">
                <li className="c-secondary-nav__list-item c-secondary-nav__list-item__menu c-secondary-nav__list-item__toggle js-toggle-menu is-priority"
                >
                  <span
                    
                    className="c-secondary-nav__link u-font--secondary-nav u-color--gray--dark u-theme--link-hover--base"
                    onClick={handleMenuClick}
                    style={{cursor: 'pointer'}}
                  >
                    <span className="u-icon u-icon--xs u-path-fill--gray">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                      >
                        <title>o-icon__menu</title>
                        <path
                          d="M0,0H10V2H0ZM0,10H10V8H0ZM0,6H10V4H0Z"
                          fill="#9b9b9b"
                        ></path>
                      </svg>
                    </span>
                    Menu
                  </span>
                </li>
              </ul>
            </nav>
          </div>
          <div className="c-header__logo c-logo">
            <span className="c-logo__link u-theme--path-fill--base">
              <img
                src={logo}
                alt="Cape Conference Logo"
                width="250"
                height="90"
              />
            </span>
          </div>

          <div className="c-header__nav-primary">
            <nav
              className="c-primary-nav c-priority-nav"
              role="navigation"
              instance="0"
            >
              <ul className="c-primary-nav__list c-priority-nav__list">
                <li className="c-primary-nav__list-item">
                  <NavLink
                    to="/"
                    className="c-primary-nav__link u-font--primary-nav u-color--gray--dark u-theme--link-hover--base u-theme--border-color--base"
                    activeClassName="this-is-active"
                    exact
                  >
                    <FontAwesomeIcon icon={faList} />
                    &nbsp; Records
                  </NavLink>
                </li>
                {activeUser.category === "admin" && (
                  <HeaderMenu
                    menuIcon={faCog}
                    menuTitle="Admin"
                    menuItems={[
                      {
                        title: "Duplicate Entries",
                        path: "/records/duplicates",
                      },
                      {
                        title: "Entities",
                        path: "/entities",
                      },
                      {
                        title: "Users",
                        path: "/users",
                      },
                      {
                        title: "Settings",
                        path: "/settings",
                      },
                    ]}
                  />
                )}

                <HeaderMenu
                  menuIcon={faUser}
                  menuTitle={
                    activeUser.last_name || activeUser.first_name || "User"
                  }
                  menuItems={[
                    {
                      title: `${activeUser.first_name} ${activeUser.last_name}`,
                      path: null,
                    },
                    {
                      title: `User Profile`,
                      path: "/profile",
                    },
                    {
                      title: "Logout",
                      path: "/logout",
                    },
                  ]}
                />
              </ul>

              <span
                className="c-priority-nav__dropdown-wrapper"
                aria-haspopup="false"
              >
                <button
                  aria-controls="menu"
                  type="button"
                  className="js-toggle-menu c-priority-nav__dropdown-toggle c-priority-nav--is-hidden"
                  prioritynav-count="0"
                ></button>
                <ul
                  aria-hidden="true"
                  className="c-priority-nav__dropdown"
                ></ul>
              </span>
            </nav>
          </div>
        </div>
      </header>
      <DrawerMenu
        isOpen={isDrawerOpen}
        onClickCloseDrawer={closeDrawer}
        userDisplayName={`${activeUser.first_name} ${activeUser.last_name}`}
        isAdmin={activeUser.category === "admin"}
      />
      {(isDrawerOpen ? '' : '') /* force react to re render draw menu*/}
    </>
  );
};

function mapStateToProps(state) {
  const appState = { userStatus: state.user, apiStatus: state.api };
  return appState;
}

export default connect(mapStateToProps)(Header);
