import constants from "../../constants";
import apiService from "../../services/api/apiService";

const loadRecordsSuccess = (records) => {
  return {
    type: constants.reduxActions.LOAD_RECORDS_SUCCESS,
    records,
  };
};

const loadRecordsFailure = () => {
  return {
    type: constants.reduxActions.LOAD_RECORDS_FAILURE,
  };
};

const loadRecords = () => {
  return (dispatch) => {
    return apiService
      .get(`${constants.envConstants.BASE_API_URL}/record`)
      .then((result) => {
        dispatch(loadRecordsSuccess(result.data));
      })
      .catch((error) => {
        dispatch(loadRecordsFailure());
      });
  };
};

const loadDuplicates = () => {
  return (dispatch) => {
    return apiService
      .get(`${constants.envConstants.BASE_API_URL}/record/duplicates`)
      .then((result) => {
        dispatch({
          type: constants.reduxActions.LOAD_DUPLICATES_SUCCESS,
          duplicates: result.data,
        });
      })
      .catch((error) => {
        dispatch(loadRecordsFailure());
      });
  };
};

const deleteRecord = (recordId) => {
  return (dispatch) => {
    return apiService
      .delete(`${constants.envConstants.BASE_API_URL}/record/${recordId}`)
      .then((result) => {
        dispatch({
          type: constants.reduxActions.DELETE_RECORD_SUCCESS,
          recordId,
        });
      })
      .catch((error) => {
        throw error;
        // dispatch(loadRecordsFailure());
      });
  };
};

const batchDeleteRecords = (records) => {
  const queryStr = records.join("&ids[]=");
  return (dispatch) => {
    return apiService
      .delete(`${constants.envConstants.BASE_API_URL}/record?ids[]=${queryStr}`)
      .then((result) => {
        dispatch({
          type: constants.reduxActions.BATCH_DELETE_RECORD_SUCCESS,
          records,
        });
      })
      .catch((error) => {
        throw error;
        // dispatch(loadRecordsFailure());
      });
  };
};

const recordActions = {
  batchDeleteRecords,
  deleteRecord,
  loadDuplicates,
  loadRecords,
  loadRecordsFailure,
  loadRecordsSuccess,
};

export default recordActions;
