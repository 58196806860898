import { useEffect, useRef } from "react";
import glogo from "./g-logo.png";

const GoogleSignInButton = ({ onGoogleLoginCallback, disabled }) => {
  const clientId =
    "651228415415-hpa53afafef5q6bpbfg0b1fflohph83e.apps.googleusercontent.com";

  let auth2;
  const signinEl = useRef(null);

  function googleInit() {
    if (typeof gapi == "undefined") {
      return;
    }
    // eslint-disable-next-line no-undef
    gapi.load("auth2", () => {
      // eslint-disable-next-line no-undef
      auth2 = gapi.auth2.init({
        client_id: clientId,
        cookiepolicy: "single_host_origin",
        prompt: "select_account",
      });
      attachSignin(signinEl.current);
    });
  }
  function handlePostLogin(googleUser) {
    const token = googleUser.getAuthResponse().id_token;
    return onGoogleLoginCallback(token);
  }
  function attachSignin(element) {
    auth2.attachClickHandler(element, {}, handlePostLogin);
  }

  useEffect(() => {
    googleInit();
  }, []);

  return (
    <button
      type="button"
      className={`o-button o-button--outline ${disabled ? "disabled" : ""}`}
      disabled={disabled}
      ref={signinEl}
    >
      <img src={glogo} alt="" width="18" height="18" style={{ marginRight: "24px" }} />{" "}
      Sign in with Google{" "}
    </button>
  );
};

export default GoogleSignInButton;
