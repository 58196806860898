import React, { useLayoutEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import HomePage from "./home/HomePage";
import LoginPage from "./login/LoginPage";
import LogoutPage from "./login/LogoutPage";
import SignUpPage from "./signup/SignUpPage";
import ForgotPassword from "./forgot-password/ForgotPassword";
import VerifyUser from "./verify/VerifyUser";
import SetNewPassword from "./set-new-password/SetNewPassword";

const AppRouter = () => {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/logout" component={LogoutPage} />
      <Route path="/signup" component={SignUpPage} />
      <Route path="/verify/:slug" component={VerifyUser} />
      <Route path="/reset-password" component={SetNewPassword} />
      <Route path="/forgot" component={ForgotPassword} />
      <Route path="/records" component={HomePage} />
      <Route path="/" component={HomePage} />
      {/* <Route path="/">
        <Redirect to="/home" />
      </Route> */}
    </Switch>
  );
};

export default AppRouter;
