import { useMemo } from "react";

const DataTablePageSelectorDropdown = ({
  pageSizeOptions,
  currentPageSize,
  onChangePageSize,
}) => {
  const handlePageChange = (e) => {
    onChangePageSize(Number(e.target.value));
  };

  const pageOptions = useMemo(
    () =>
      pageSizeOptions.map((pageSize) => (
        <option key={pageSize} value={pageSize}>
          Show {pageSize}
        </option>
      )),
    [pageSizeOptions]
  );

  return (
    <select
      value={currentPageSize}
      onChange={handlePageChange}
      style={{ width: "initial", appearance: "button" }}
      aria-label="Select Rows per Page"
    >
      {pageOptions}
    </select>
  );
};

export default DataTablePageSelectorDropdown;
