import constants from "../../constants";

export default function entitiesReducer(state = { entities: [] }, action) {
  switch (action.type) {
    case constants.reduxActions.LOAD_ENTITIES_SUCCESS:
      return { ...state, entities: action.entities };
    case constants.reduxActions.LOAD_ENTITIES_FAILURE:
      return { ...state, entities: null };
    case constants.reduxActions.DELETE_ENTITY_SUCCESS:
      return { ...state, entities: state.entities.filter(entity => entity.id !== action.entityId) };
    case constants.reduxActions.BATCH_DELETE_ENTITY_SUCCESS:
        return {
          ...state,
          entities: state.entities.filter(
            (entity) => !action.entities.includes(entity.id)
          ),
        };
    case constants.reduxActions.LOGOUT_USER:
      return { ...state, entities: null };
    default:
      return state;
  }
}
