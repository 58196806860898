import loadingIndicator from "../../assets/loading-indicator.gif";
import "./LoadingIndicator.css";

const LoadingIndicator = (props) => {
  const { fullscreen, horizontal } = props || false;
  const backdropStyle = {
    margin: 0,
    position: "fixed",
    background: "#ddd",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: "0.5",
    zIndex: "999999",
  };
  const indicatorParentStyle = {
    margin: 0,
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "999999",
    alpha: 0.7,
    backdropFilter: "blur(5px)",
  };
  const indicatorStyle = {
    padding: "0",
    margin: "0",
    display: "block",
    width: "100%",
    textAlign: "center",
  };

  return (
    <>
    {horizontal && <div className="working-indicator"></div>}
    {!horizontal && (
      <>
      {fullscreen && (
        <div>
          <div style={backdropStyle}></div>
          <div style={indicatorParentStyle}>
            <div style={indicatorStyle}>
              <img src={loadingIndicator} alt="Logo" />
            </div>
          </div>
        </div>
      )}
      {!fullscreen && (
        <div style={indicatorStyle}>
          <img src={loadingIndicator} alt="Logo" />
        </div>
      )}</>
     ) }
    </>
  );
};

export default LoadingIndicator;
