import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Snack.css";

const Snack = ({ appearance, children }) => (
  <div className={`alps-snackbar ${appearance === "error"? 'error' : 'success'}`}>
    <div
      className={`snackbar-label ${appearance === "error"? 'error' : 'success'}`}
    >
      <FontAwesomeIcon icon={faInfo} />
    </div>
    <div className="snackbar-content"
    >
      {children}
    <div style={{color: '#aaa', textAlign:'right', fontSize:'0.5rem'}}>
      {new Date().toLocaleString()}
    </div>
    </div>
  </div>
);

export default Snack;
