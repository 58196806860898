import { ErrorMessage } from "formik";

const StyledErrorMessage = (props) => (
  <div className="u-spacing--double">
    <span className="text-danger ">
      <ErrorMessage {...props} />
    </span>
  </div>
);

export default StyledErrorMessage;
