import constants from "../../constants";
import apiService from "../../services/api/apiService";

const loginUserSuccess = (data) => {
  localStorage.setItem("token", JSON.stringify(data.access_token));
  localStorage.setItem("user", JSON.stringify(data.user));
  apiService.authManager.updateAuthorization();
  return {
    type: constants.reduxActions.LOGIN_USER,
    data,
  };
};

const loginUser = (userCredentials) => {
  return (dispatch) => {
    return apiService
      .post(`${constants.envConstants.BASE_API_URL}/auth`, userCredentials)
      .then((result) => {
        dispatch(loginUserSuccess(result));
      })
      .catch((error) => {
        dispatch({
          type: constants.reduxActions.LOGIN_ERROR,
          errorMessage: apiService.responseHandler.describeError(error),
        });
      });
  };
};

const updateCurrentUserProfile = (id) => {
  if (id) {
    return (dispatch) =>
      apiService
        .get(`${constants.envConstants.BASE_API_URL}/user/${id}`)
        .then((result) => {
          const { data } = result;
          localStorage.setItem("user", JSON.stringify(data));
          dispatch({
            type: constants.reduxActions.UPDATE_CURRENT_USER_PROFILE,
            user: data,
          });
        })
        .catch((error) => {});
  }
  return { type: "INVALID_USERID_ENCOUNTERED" };
};

const loadAllUsers = () => (dispatch) =>
  apiService
    .get(`${constants.envConstants.BASE_API_URL}/user`)
    .then((result) => {
      const { data } = result;
      dispatch({
        type: constants.reduxActions.LOAD_ALL_USERS_SUCCESS,
        users: data,
      });
    });

const logoutUser = () => {
  localStorage.clear();
  return {
    type: constants.reduxActions.LOGOUT_USER,
  };
};

const deleteUser = (id) => {
  return (dispatch) => {
    return apiService
      .delete(`${constants.envConstants.BASE_API_URL}/user/${id}`)
      .then((result) => {
        dispatch({
          type: constants.reduxActions.DELETE_USER_SUCCESS,
          userId: id,
        });
      });
    // error handling on component side so component can show toast
  };
};


const batchDeleteUsers = (userIds) => {
  const queryStr = userIds.join("&ids[]=");
  return (dispatch) => {
    return apiService
      .delete(`${constants.envConstants.BASE_API_URL}/user?ids[]=${queryStr}`)
      .then((result) => {
        dispatch({
          type: constants.reduxActions.BATCH_DELETE_USER_SUCCESS,
         userIds,
        });
      })
  };
};

const updateBlockUser = (id, block = true) => {
  return (dispatch) => {
    return apiService
      .patch(`${constants.envConstants.BASE_API_URL}/user/${id}`, {
        blocked: block ? 1 : 0,
      })
      .then((result) => {
        dispatch({
          type: block
            ? constants.reduxActions.BLOCK_USER_SUCCESS
            : constants.reduxActions.UNBLOCK_USER_SUCCESS,
          userId: id,
        });
      });
    // error handling on component side so component can show toast
  };
};


const updateVerifyUser = (id) => {
  return (dispatch) => {
    return apiService
      .patch(`${constants.envConstants.BASE_API_URL}/user/${id}`, {
        verified: true
      })
      .then((result) => {
        dispatch({
          type: constants.reduxActions.VERIFY_USER_SUCCESS,
          userId: id,
        });
      });
    // error handling on component side so component can show toast
  };
};


const userActions = {
  loginUser,
  logoutUser,
  updateCurrentUserProfile,
  loadAllUsers,
  deleteUser,
  batchDeleteUsers,
  updateBlockUser,
  updateVerifyUser
};
export default userActions;
