import { DialogActions, DialogContent } from "@material-ui/core";
import { closeModalDialog } from "../../utils/sharedUtils";

const ConfirmationDialog = (props) => {
  const handleOk = () => {
    props.successBtnCallback();
    closeModalDialog();
  };
  const handleCancel = () => {
    if (props.cancelBtnCallback) {
      props.cancelBtnCallback();
    }
    closeModalDialog();
  };

  return (
    <>
    <DialogContent dividers>
    <p className="u-font--secondary--s">{props.message}</p>
    </DialogContent>
    <DialogActions>
      <button onClick={handleOk} className="o-button" style={{ minWidth: 100 }}>
        OK
      </button>
      &nbsp;
      <button onClick={handleCancel} autoFocus={true} className="o-button o-button--outline">
        Cancel
      </button>
      </DialogActions>
    </>
  );
};

export default ConfirmationDialog;
