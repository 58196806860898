import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import logo from "../../assets/logo.svg";
import constants from "../../constants";
import apiService from "../../services/api/apiService";
import ArrowRight from "../shared/ArrowRight";
import AlpsLayoutContainer from "../shared/AlpsLayoutContainer";
import LoadingIndicator from "../shared/LoadingIndicator";
import ResendEmailForm from "./ResendEmailForm";

const VerifyUser = (props) => {
  const location = useLocation();

  const params = useParams();

  const [verifyStatus, setVerifyStatus] = useState({
    attempted: false,
    success: false,
    errorMessage: null,
  });

  const [resendEmailMode, setResendEmailMode] = useState(false);
  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };
  const verifyUser = () => {
    const apiVerifyString = decodeHtml(`${params.slug}${location.search}`);
    setVerifyStatus({
      attempted: false,
      success: false,
      errorMessage: null,
    });
    apiService
      .get(
        `${constants.envConstants.BASE_API_URL}/user/verify/${apiVerifyString}`
      )
      .then((result) => {
        setVerifyStatus({ attempted: true, success: true, errorMessage: null });
      })
      .catch((error) => {
        setVerifyStatus({
          attempted: true,
          success: false,
          errorMessage: apiService.responseHandler.describeError(error),
        });
      });
  };

  useEffect(verifyUser, []);

  return AlpsLayoutContainer(
    <div>
      <div className="text u-spacing u-space--top 1-grid">
        <img src={logo} alt="Cape Conference Logo" className="loginLogo" />
        {!resendEmailMode && (
          <>
            <h2 className="u-font--secondary--l u-theme--color--darker">
              {verifyStatus.attempted
                ? verifyStatus.errorMessage
                  ? "Verification Failed"
                  : "Verification Success"
                : "Verifying..."}
            </h2>
            <div>
              {!verifyStatus.attempted && !verifyStatus.errorMessage && (
                <LoadingIndicator fullscreen={false} />
              )}
              {verifyStatus.errorMessage && (
                <>
                  <div className="alert alert-danger" role="alert">
                    {verifyStatus.errorMessage}
                  </div>
                  <br />
                  <button
                    type="submit"
                    className="o-button o-button--outline"
                    onClick={() => setResendEmailMode(true)}
                  >
                    Resend Email{" "}
                  </button>{" "}
                </>
              )}
              {verifyStatus.success && (
                <>
                  <div className="alert alert-success" role="alert">
                    {constants.messages.VERIFICATION_SUCCESS}
                  </div>
                  <br />
                  <Link to="/login" className="o-button">
                    Go to Login <ArrowRight />
                  </Link>
                  <br />
                  <br />
                  <br />
                </>
              )}
            </div>
          </>
        )}
        {resendEmailMode && <ResendEmailForm />}
      </div>
    </div>
  );
};

export default VerifyUser;
