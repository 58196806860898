import constants from "../../constants";
import apiService from "../../services/api/apiService";

const loadEntitiesSuccess = (entities) => {
  return {
    type: constants.reduxActions.LOAD_ENTITIES_SUCCESS,
    entities,
  };
}

const loadEntitiesFailure = () => {
  return {
    type: constants.reduxActions.LOAD_ENTITIES_FAILURE,
  };
}

const loadEntities = () => {
  return (dispatch) => {
    return apiService
      .get(`${constants.envConstants.BASE_API_URL}/entity`)
      .then((result) => {
        dispatch(loadEntitiesSuccess(result.data));
      })
      .catch((error) => {
        dispatch(loadEntitiesFailure());
      });
  };
};

const loadSingleEntity = (entityId) => {
  return (dispatch) => {
    return apiService
      .get(`${constants.envConstants.BASE_API_URL}/entity/${entityId}`)
      .then((result) => {
        const limitedEntryList = [];
        limitedEntryList.push(result.data);
        dispatch(loadEntitiesSuccess(limitedEntryList));
      })
      .catch((error) => {
        dispatch(loadEntitiesFailure());
      });
  };
}

const deleteEntity = (entityId) => {
  return (dispatch) => {
    return apiService
      .delete(`${constants.envConstants.BASE_API_URL}/entity/${entityId}`)
      .then((result) => {
        dispatch({
          type: constants.reduxActions.DELETE_ENTITY_SUCCESS,
          entityId,
        });
      })
      .catch((error) => {
        dispatch(loadEntitiesFailure());
      });
  };
}

const batchDeleteEntity = (entities) => {
  const queryStr = entities.join("&ids[]=");
  return (dispatch) => {
    return apiService
      .delete(`${constants.envConstants.BASE_API_URL}/entity?ids[]=${queryStr}`)
      .then((result) => {
        dispatch({
          type: constants.reduxActions.BATCH_DELETE_ENTITY_SUCCESS,
          entities,
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

const entitiesActions = {
  batchDeleteEntity,
  deleteEntity,
  loadEntities,
  loadSingleEntity,
  loadEntitiesFailure,
  loadEntitiesSuccess
}

export default entitiesActions;
