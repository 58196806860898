import * as Yup from "yup";
import constants from "../constants";

const userYupSchema = (complete = true) => Yup.object().shape({
    firstName: Yup.string()
      .min(2, constants.messages.FIELD_VALUE_SHORT)
      .max(40, constants.messages.FIELD_VALUE_LONG)
      .required(constants.messages.FIELD_REQUIRED),
    lastName: Yup.string()
      .min(2, constants.messages.FIELD_VALUE_SHORT)
      .max(40, constants.messages.FIELD_VALUE_LONG)
      .required(constants.messages.FIELD_REQUIRED),
    phone: Yup.string()
      .matches(
        constants.regexExpressions.PHONE_NUMBER,
        constants.messages.FIELD_INVALID_PHONE
      )
      .required(constants.messages.FIELD_REQUIRED),
    password: complete ? Yup.string()
      .min(4, constants.messages.FIELD_VALUE_SHORT)
      .max(40, constants.messages.FIELD_VALUE_LONG)
      .required(constants.messages.FIELD_REQUIRED) : null,
    passwordConfirm: complete ? Yup.string()
      .required(constants.messages.FIELD_REQUIRED)
      .test(
        "passwords-match",
        constants.messages.FIELD_PASSWORD_MUST_MATCH,
        function (value) {
          return this.parent.password === value;
        }
      ) : null,
    email: complete ? Yup.string()
      .email(constants.messages.FIELD_INVALID_EMAIL)
      .required(constants.messages.FIELD_REQUIRED) : null,
    address: Yup.string()
      .min(12, constants.messages.FIELD_VALUE_SHORT)
      .required(constants.messages.FIELD_REQUIRED),
    entity: Yup.number()
      .min(0, "Please select an entity")
      .required(constants.messages.FIELD_REQUIRED),
  });

  export default userYupSchema;