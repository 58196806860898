import { combineReducers } from "redux";
import user from "./userReducer";
import entitiesState from "./entitiesReducer";
import api from "./apiReducer";
import recordsState from "./recordsReducer";
import modal from "./modalReducer";

const rootReducer = combineReducers({
  entitiesState,
  user,
  api,
  recordsState,
  modal
});

export default rootReducer;
