import { useState } from "react";
import { Redirect } from "react-router-dom";
import AlpsContentContainer from "../shared/AlpsContentContainer";

import DisplayMessageCard from "../shared/DisplayMessageCard";

const PageNotFound = () => {
  const [redirectTo, setRedirectTo] = useState(null);
  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }
  
  return AlpsContentContainer(<DisplayMessageCard message="Page could not be found" actionTitle="Go Home" action={()=>setRedirectTo('/')} />);
};

export default PageNotFound;
