import constants from "../../constants";

const filterOutId = (collection, recordId) =>
  collection.filter((entity) => entity.id !== recordId);

const deepFilterOutId = (collection, itemId) => {
  const filtered = collection.map((set) => filterOutId(set, itemId));
  return filtered.filter((set) => set.length > 1);
};

export default function recordsReducer(
  state = { records: [], duplicates: null },
  action
) {
  switch (action.type) {
    case constants.reduxActions.LOAD_RECORDS_SUCCESS:
      return { ...state, records: action.records };
    case constants.reduxActions.LOAD_DUPLICATES_SUCCESS:
      return { ...state, duplicates: action.duplicates };
    case constants.reduxActions.LOAD_RECORDS_FAILURE:
      return { ...state, records: null };
    case constants.reduxActions.DELETE_RECORD_SUCCESS:
      const newStateDuplicates = state.duplicates
        ? {
            similar: deepFilterOutId(state.duplicates.similar, action.recordId),
            identical: deepFilterOutId(
              state.duplicates.identical,
              action.recordId
            ),
          }
        : state.duplicates;
      return {
        ...state,
        records: state.records.filter(
          (entity) => entity.id !== action.recordId
        ),
        duplicates: newStateDuplicates,
      };
    case constants.reduxActions.BATCH_DELETE_RECORD_SUCCESS:
      return {
        ...state,
        records: state.records.filter(
          (entity) => !action.records.includes(entity.id)
        ),
      };
    case constants.reduxActions.LOGOUT_USER:
      return { ...state, records: null };
    default:
      return state;
  }
}
