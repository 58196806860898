import { Checkbox } from "@material-ui/core";
import { useEffect, useState } from "react";
import { nanoid } from "nanoid";

const IndeterminateCheckbox = ({onChange, checked, disabled, title, indeterminate}) => {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const elemId = `checkbox-${nanoid()}`;

  return (
    <div style={{ width: 48, display: "inline-block", textAlign: "center" }}>
      <Checkbox
        disabled={disabled}
        checked={isChecked}
        id={elemId}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          if(disabled){
            return;
          }
          onChange({ target: { checked: !checked } });
        }} // @chif. made a virtual event object because row.toggleRowSelected, will use e.target.checked
        title={title}
        aria-label={title}
        indeterminate={indeterminate}
        color="primary"
      />
      <label htmlFor={elemId} className="hidden-label">{title}</label>
    </div>
  );
};

export default IndeterminateCheckbox;
