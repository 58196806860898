import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import logo from "../../assets/logo.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ConnectionErrorCard from "../shared/ConnectionErrorCard";
import "./SignUpPage.css";
import LoadingIndicator from "../shared/LoadingIndicator";
import constants from "../../constants";
import StyledErrorMessage from "../shared/StyledErrorMessage";
import { setFormInputStyle } from "../../utils/sharedUtils";
import apiService from "../../services/api/apiService";
import entitiesActions from "../../redux/actions/entitiesActions";
import userYupSchema from "../../userYupSchema/userSchema";
import { useToasts } from "react-toast-notifications";
import AlpsLayoutContainer from "../shared/AlpsLayoutContainer";

const SignUpPage = (props) => {
  const { isWorking } = props.apiStatus;

  const [registrationStatus, setRegistrationStatus] = useState({
    success: false,
    errorMessage: null,
  });

  const { addToast } = useToasts();

  useEffect(() => {
    if (!props.entities || props.entities.length < 1) {
      props.reduxActions.loadEntities();
    }
  }, []);
  const registerUser = (userInfo) => {
    apiService
      .post(`${constants.envConstants.BASE_API_URL}/user`, userInfo)
      .then((result) =>
        setRegistrationStatus({ success: true, errorMessage: null })
      )
      .catch((error) => {
        setRegistrationStatus({
          success: false,
          errorMessage: apiService.responseHandler.describeError(error),
        });
      });
  };

  const handleSubmit = (values) => {
    registerUser({
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.contactNumber,
      address: values.address,
      email: values.email,
      password: values.password,
      entity: values.entity,
    });
  };

  const handleFailSubmit = () => {
    addToast(constants.messages.FILL_ALL_FIELDS, {
      appearance: "error",
    });
  };

  const defaultValues = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    entity: "-1",
    password: "",
    passwordConfirm: "",
  };

  return AlpsLayoutContainer(
    <>
      {" "}
      {!props.entities && <ConnectionErrorCard />}
      {props.entities && (
        <Formik
          initialValues={defaultValues}
          validationSchema={userYupSchema()}
          isInitialValid={false}
        >
          {({ errors, touched, values, isValid }) => (
            <Form
              style={{ width: "100%" }}
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                isValid ? handleSubmit(values) : handleFailSubmit();
              }}
            >
              <div className="text u-spacing u-space--top 1-grid">
                <img
                  src={logo}
                  alt="Cape Conference Logo"
                  className="loginLogo"
                  width="250"
                  height="90"
                />
                <h2 className="no-left-margin u-font--secondary--l u-theme--color--darker">
                  Sign up
                </h2>
                {!registrationStatus.success && (
                  <>
                    <label className="u-font--secondary--s">
                      Email*
                      <Field
                        type="email"
                        name="email"
                        className={setFormInputStyle(
                          errors,
                          touched,

                          "email"
                        )}
                      />
                    </label>
                    <StyledErrorMessage name="email" />

                    <label className="u-font--secondary--s">
                      Password*
                      <Field
                        type="password"
                        name="password"
                        className={setFormInputStyle(
                          errors,
                          touched,

                          "password"
                        )}
                      />
                    </label>
                    <StyledErrorMessage name="password" />

                    <label className="u-font--secondary--s">
                      Re-enter Password
                      <Field
                        type="password"
                        name="passwordConfirm"
                        className={setFormInputStyle(
                          errors,
                          touched,

                          "passwordConfirm"
                        )}
                      />
                    </label>
                    <StyledErrorMessage name="passwordConfirm" />
                    <br />

                    <label className="u-font--secondary--s">
                      First Name*
                      <Field
                        type="text"
                        name="firstName"
                        className={setFormInputStyle(
                          errors,
                          touched,

                          "firstName"
                        )}
                      />
                    </label>
                    <StyledErrorMessage name="firstName" />

                    <label className="u-font--secondary--s">
                      Last Name*
                      <Field
                        type="text"
                        name="lastName"
                        className={setFormInputStyle(
                          errors,
                          touched,

                          "lastName"
                        )}
                      />
                    </label>
                    <StyledErrorMessage name="lastName" />
                    <label className="u-font--secondary--s">
                      Contact Number
                      <Field
                        type="tel"
                        name="phone"
                        className={setFormInputStyle(
                          errors,
                          touched,

                          "phone"
                        )}
                      />
                    </label>
                    <StyledErrorMessage name="phone" />

                    <label>
                      Address
                      <Field
                        name="address"
                        as="textarea"
                        className={setFormInputStyle(
                          errors,
                          touched,

                          "address"
                        )}
                      />
                    </label>
                    <StyledErrorMessage name="address" />

                    <label>
                      Church/Entity
                      <Field
                        as="select"
                        name="entity"
                        className={setFormInputStyle(
                          errors,
                          touched,

                          "entity"
                        )}
                      >
                        <option value="-1">Select Church/Entity...</option>
                        {props.entities.map((entity) => (
                          <option key={entity.id} value={entity.id}>
                            {entity.name}
                          </option>
                        ))}
                      </Field>
                    </label>

                    <StyledErrorMessage name="entity" />

                    <>
                      {!isWorking && registrationStatus.errorMessage && (
                        <div className="alert alert-danger" role="alert">
                          {registrationStatus.errorMessage}
                        </div>
                      )}

                      {isWorking && <LoadingIndicator fullscreen={false} />}
                    </>

                    <div className="u-flex u-flex--align-center">
                      <button
                        type="submit"
                        className={`o-button ${isWorking ? "disabled" : ""}`}
                        disabled={isWorking}
                      >
                        Sign Up{" "}
                      </button>{" "}
                      &nbsp;{" "}
                      {!isWorking && (
                        <Link
                          to="login"
                          className={`o-button o-button--outline ${
                            isWorking ? "disabled" : ""
                          }`}
                        >
                          Cancel
                        </Link>
                      )}
                    </div>
                  </>
                )}

                {registrationStatus.success && (
                  <>
                    <div className="alert alert-success" role="alert">
                      {constants.messages.REGISTRATION_SUCCESS}
                    </div>
                    <br />
                    <Link to="/login" className="o-button">
                      Go to Login
                    </Link>
                    <br />
                    <br />
                    <br />
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    apiStatus: state.api,
    entities: state.entitiesState.entities,
  };
};

const mapDispathToProps = (dispatch) => {
  return {
    reduxActions: bindActionCreators(entitiesActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispathToProps)(SignUpPage);
