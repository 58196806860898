import IndeterminateCheckbox from "../components/shared/IndeterminateCheckbox";
import { closeModal, showModal } from "../redux/actions/modalActions";
import { store } from "../redux/myStore";

const linkToNoWhere = (e) => e.preventDefault();

const setFormInputStyle = (errors, touched, field) => {
  return touched[field] ? (errors[field] ? "has-error" : "is-valid") : "";
};

const showModalDialog = (content, title = null) =>
  store.dispatch(showModal(content, title));

const closeModalDialog = () => store.dispatch(closeModal());

const riskType = (data, forceCompliance = false) => {
  switch (data) {
    case "bldg":
      return "Building";
    case "allrisk":
      return "All Risk";
    case "contents":
      return "Contents";
    case "bldg/cont":
      return "Build+Cont";
    default:
      return forceCompliance ? null : data;
  }
};

const sliceByWord = (phrase, length, skipEllipses = false) => {
  if (phrase.length < length) return phrase;
  else {
    let trimmed = phrase.slice(0, length);
    trimmed = trimmed.slice(
      0,
      Math.min(trimmed.length, trimmed.lastIndexOf(" "))
    );
    return skipEllipses ? trimmed : trimmed + "…";
  }
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "ZAR",
});

const formatMoney = (value, showSymbol = false) =>
  showSymbol
    ? formatter.format(value)
    : formatter.format(value).replace("ZAR", "");

const sumUpAmounts = (...amounts) => {
  const total = amounts.reduce((previous, current) => {
    return previous + (isNaN(parseFloat(current)) ? 0 : parseFloat(current));
  }, 0);
  return total;
};

const formatRecordForDisplay = (entry) => ({
  ...entry,
  contents_value_formatted:
    entry.contents_value === 0 ? "" : formatMoney(entry.contents_value),
  building_value_formatted:
    entry.building_value === 0 ? "" : formatMoney(entry.building_value),
  entity_name: sliceByWord(entry.entity_name, 20, false),
  erf: sliceByWord(entry.erf, 20, false),
  description: sliceByWord(entry.description, 20, false),
  full_description: entry.description,
  type: riskType(entry.type),
  total_value_formatted: formatMoney(
    entry.building_value + entry.contents_value
  ),
  total_value: entry.building_value + entry.contents_value,
});
const mapRecordsForDisplay = (records) => records.map(formatRecordForDisplay);

const calcPremium = (value) => {
  return (isNaN(parseFloat(value)) ? 0 : parseFloat(value)) * 0.0019;
};

const generateSelectionColumn = () => {
  return {
    id: "selection",
    Header: ({ getToggleAllPageRowsSelectedProps }) => (
      <span>
        <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
        &nbsp;{/*provide some padding for material checkbox */}
      </span>
    ),
    Cell: ({ row }) => (
      <>
        <span>
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          &nbsp;{/*provide some padding for material checkbox */}
        </span>
      </>
    ),
  };
};

const generateTableHeader = (headerGroups, sortable = false) => {
  return headerGroups.map((headerGroup) => (
    <tr
      {...headerGroup.getHeaderGroupProps()}
      className="data-table-header-row"
    >
      {headerGroup.headers.map((column) => (
        <th
          {...(sortable
            ? column.getHeaderProps(column.getSortByToggleProps())
            : column.getHeaderProps())}
          style={{
            textAlign: column.id === "actions" ? "right" : "left",
            cursor: column.canSort ? "pointer" : "",
          }}
        >
          <>
            {sortable && (
              <span
                className={`header-sort-icon ${
                  column.isSorted
                    ? column.isSortedDesc
                      ? "sort-up"
                      : "sort-down"
                    : column.canSort
                    ? "sort"
                    : ""
                }`}
              ></span>
            )}
            {column.render("Header")}
          </>
        </th>
      ))}
    </tr>
  ));
};

const uiTransformers = {
  riskType,
  sliceByWord,
  formatMoney,
  formatRecordForDisplay,
  mapRecordsForDisplay,
};

export {
  calcPremium,
  sumUpAmounts,
  linkToNoWhere,
  setFormInputStyle,
  showModalDialog,
  closeModalDialog,
  uiTransformers,
  generateSelectionColumn,
  generateTableHeader,
};
