import constants from "../../constants";

export default function apiReducer(
  state = { isWorking: false, success: false, errorMessage: null },
  action
) {
  switch (action.type) {
    case constants.reduxActions.API_REQUEST_CLEAR_ERROR:
      return { ...state, errorMessage: null };
    case constants.reduxActions.API_REQUEST_IN_PROGRESS:
      return { ...state, isWorking: true, errorMessage: null };
    case constants.reduxActions.API_REQUEST_FAILURE:
      return { isWorking: false, success: false, errorMessage: action.message };
    case constants.reduxActions.API_REQUEST_SUCCESS:
      return { isWorking: false, success: true };
    default:
      return state;
  }
}
