const AlpsContentContainer = (content, showHeader = false, padded = false) => {
  return (
    <>
      {showHeader}
      {padded && (
        <section
          id="top"
          className="l-main__content l-grid l-grid--7-col u-shift--left--1-col--at-large l-grid-wrap--6-of-7 u-spacing--double--until-xxlarge u-padding--zero--sides"
        >
          <article className="c-article l-grid-item l-grid-item--l--4-col">
            <div className="c-article__body">
              <div className="u-spacing--double text u-spacing">{content}</div>
            </div>
          </article>
        </section>
      )}
      {!padded && (
        <section id="top" className="l-main__content  ">
          <article className="">
            <div className="col-12">
              <div className="text u-space--top">{content}</div>
            </div>
          </article>
        </section>
      )}
    </>
  );
};

export default AlpsContentContainer;
