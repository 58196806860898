import constants from "../../constants";

export default function modalReducer(state = { isOpen: false, content: null, title: null}, action) {
  switch (action.type) {
    case constants.reduxActions.MODAL_OPEN:
      return { ...state, isOpen: true, content: action.content, title: action.title };
    case constants.reduxActions.MODAL_CLOSE:
      return { ...state, isOpen: false, content: null, title: null };
    default:
      return state;
  }
}
