import * as displayMessages from "./displayMessages"
import * as actionTypes from "./actionTypes";
import * as envConstants from "./env";
import * as regExp from "./regexp";

const constants = {
  envConstants,
  reduxActions: actionTypes,
  messages: displayMessages,
  regexExpressions: regExp
};

export default constants;
