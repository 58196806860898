import { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import userActions from '../../redux/actions/userActions';

class LogoutPage extends Component {
  componentWillMount() {
    this.props.dispatch(userActions.logoutUser())
  }

  render() {
    return <Redirect to={'/login'}/>
  }
}
LogoutPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

export default connect()(LogoutPage)