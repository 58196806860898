/**
 *  Handles API management by wrapping around redux and dispatching varios redux actions
 */
import axios from "axios";
import constants from "../../constants";
import apiActions from "../../redux/actions/apiActions";
import { store } from "../../redux/myStore";

const handleResponse = async (response) => {
  dispatchSuccess();
  if (response.status > 199 && response.status < 300) {
    return response.data;
  }
  throw new Error("Network response was not ok.");
};

// attempt to provide front end information based on error code
const describeError = (error) => {
  if (!error.response) {
    return constants.messages.NETWORK_ERROR;
  }
  if (error.response.data) {
    // first priority. errors that we describe from the backend are found here
    if (error.response.data.error) {
      return error.response.data.error;
    }
    // second priority. these are errors from laravel
    if (error.response.data.message) {
      return error.response.data.message;
    }
    // third priority. generic description of status codes
    if (error.response.statusText) {
      return error.response.statusText;
    }
  }
  return constants.messages.ERROR_OCCURED;
};

// In a real app, would likely call an error logging service.
const handleError = async (error) => {
  dispatchFailure(error);
  // eslint-disable-next-line no-console
  // console.error("API call failed. " + error);
  throw error;
};

const dispatchSuccess = (response) => {
  store.dispatch(apiActions.setStateEndApiRequestSuccess());
  return response;
};
const dispatchFailure = (error) => {
  store.dispatch(apiActions.setStateEndApiRequestFailure(describeError(error)));
  return error;
};

const updateAuthorization = () => {
  const savedToken = localStorage.getItem("token");

  let userToken;
  try {
    if (savedToken) {
      userToken = JSON.parse(savedToken);
    }
  } catch (e) {
    // console.log("could not parse saved user token");
  }

  if (userToken) {
    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      Authorization: `Bearer ${userToken}`,
    };
  }
};

const apiService = {
  /**
   *
   * @param {string} url
   */
  get: (url) => {
    store.dispatch(apiActions.setStateMakeApiRequest());
    return axios.get(url).then(handleResponse).catch(handleError);
  },
  /**
   *
   * @param {string} url
   * @param {*} data
   */
  post: (url, data) => {
    store.dispatch(apiActions.setStateMakeApiRequest());
    return axios.post(url, data).then(handleResponse).catch(handleError);
  },
  /**
   *
   * @param {string} url
   * @param {*} data
   */
  put: (url, data) => {
    store.dispatch(apiActions.setStateMakeApiRequest());
    return axios.put(url, data).then(handleResponse).catch(handleError);
  },
  /**
   *
   * @param {string} url
   * @param {*} data
   */
  patch: (url, data) => {
    store.dispatch(apiActions.setStateMakeApiRequest());
    return axios.patch(url, data).then(handleResponse).catch(handleError);
  },
  /**
   *
   * @param {string} url
   */
  delete: (url) => {
    store.dispatch(apiActions.setStateMakeApiRequest());
    return axios.delete(url).then(handleResponse).catch(handleError);
  },
  responseHandler: {
    handleError,
    handleResponse,
    describeError,
  },
  authManager: {
    updateAuthorization,
  },
};

// updateAuthorization();

export default apiService;
