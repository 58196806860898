import { useState } from "react";
import { useAsyncDebounce } from "react-table";

const DataTableSearchBox = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    itemName
  }) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    const handleChange = (e) => {
      setValue(e.target.value);
      onChange(e.target.value);
    };
  
    return (
      <input
        type="search"
        className="u-font--secondary--s u-theme--color--darker o-input__search"
        value={value || ""}
        placeholder={`Search ${count} ${itemName? itemName : 'Entries'}...`}
        aria-label={`Search  ${itemName? itemName : 'Entries'}`}
        autoComplete="off"
        onChange={handleChange}
      />
    );
  };
  
  export default DataTableSearchBox;