import { useState } from "react";
import { Link } from "react-router-dom";
import constants from "../../constants";
import apiService from "../../services/api/apiService";
import ArrowRight from "../shared/ArrowRight";

const ResendEmailForm = (props) => {
  const [resendStatus, setResendStatus] = useState({
    attempted: false,
    success: false,
    errorMessage: null,
  });

  const [emailValue, setEmailValue] = useState("");

  const resendEmail = (e) => {
    e.preventDefault();
    setResendStatus({
      success: false,
      errorMessage: null,
    });
    apiService
      .post(`${constants.envConstants.BASE_API_URL}/user/resend`, {
        email: emailValue,
      })
      .then((result) => {
        setResendStatus({ attempted: true, success: true, errorMessage: null });
      })
      .catch((error) => {
        setResendStatus({
          attempted: true,
          success: false,
          errorMessage: apiService.responseHandler.describeError(error),
        });
      });
  };
  return (
    <form onSubmit={resendEmail} style={{ width: "100%" }}>
      <h2 className="u-font--secondary--l u-theme--color--darker">
        Resend Activation
      </h2>
      {!resendStatus.success && (
        <>
          <label>
            {constants.messages.ENTER_YOUR_EMAIL}
            <input
              type="email"
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
              required
            />
          </label>
          <br />
          <br />
          {resendStatus.errorMessage && (
            <>
              <div className="alert alert-danger" role="alert">
                {resendStatus.errorMessage}
              </div>
              <br />
            </>
          )}

          <div className="u-flex u-flex--align-center">
            <button type="submit" className="o-button">
              Resend{" "}
            </button>{" "}
            &nbsp;{" "}
            <Link to="/login" className="o-button o-button--outline">
              Cancel
            </Link>
          </div>
        </>
      )}
      {resendStatus.success && (
        <>
          <div className="alert alert-success" role="alert">
            {constants.messages.RESEND_ACTIVATION_SUCCESS}
          </div>
          <br />
          <Link to="/login" className="o-button">
            Go to Login <ArrowRight />
          </Link>
          <br />
          <br />
          <br />
        </>
      )}
    </form>
  );
};

export default ResendEmailForm;
