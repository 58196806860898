import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { linkToNoWhere } from "../../utils/sharedUtils";
import { Link } from "react-router-dom";

const HeaderMenu = ({menuIcon, menuTitle, menuItems}) => {
  return (
    <li className="c-primary-nav__list-item has-subnav">
      <a
        href="/#"
        onClick={linkToNoWhere}
        className="c-primary-nav__link u-font--primary-nav u-color--gray--dark u-theme--link-hover--base u-theme--border-color--base"
      >
        <FontAwesomeIcon icon={menuIcon} />
        &nbsp; {menuTitle}
      </a>
      <span className="c-primary-nav__arrow c-subnav__arrow o-arrow--down u-path-fill--gray"></span>
      <ul className="c-primary-nav__subnav c-subnav">
        {menuItems.map((item, index) => (
          <li
            key={index}
            className="c-primary-nav__subnav__list-item c-subnav__list-item u-background-color--gray--light u-theme--border-color--dark"
          >
            {item.path && (
              <Link
                to={item.path}
                className="c-primary-nav__subnav__link c-subnav__link u-color--gray--dark u-theme--link-hover--base"
              >
                {item.title}
              </Link>
            )}

            {!item.path && (
              <span style={{ padding: "1.25rem" }}>
                <em>{item.title}</em>
              </span>
            )}
          </li>
        ))}
      </ul>
    </li>
  );
};

export default HeaderMenu;
