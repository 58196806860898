import { ThemeProvider } from "@material-ui/core";
import React from "react";
import { ToastProvider } from "react-toast-notifications";
import alpsifiedMaterial from "../styles/alpsifyMaterial";
import "./App.css";
import AppRouter from "./AppRouter";
import Snack from "./shared/Snack";

function App() {
  return (
    <ToastProvider placement="top-center"
     components={{ Toast: Snack }}
     autoDismiss={true}
     transitionDuration={0}
     >
       <ThemeProvider theme={alpsifiedMaterial}>
      <AppRouter /></ThemeProvider>
    </ToastProvider>
  );
}

export default App;
