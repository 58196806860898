import { useState } from "react";
import { Link } from "react-router-dom";
import constants from "../../constants";
import apiService from "../../services/api/apiService";
import AlpsLayoutContainer from "../shared/AlpsLayoutContainer";
import ArrowRight from "../shared/ArrowRight";
import logo from "../../assets/logo.svg";

const ForgotPassword = () => {
  const [resetPasswordStatus, setResetPasswordStatus] = useState({
    attempted: false,
    success: false,
    errorMessage: null,
  });

  const [emailValue, setEmailValue] = useState("");

  const resendEmail = (e) => {
    e.preventDefault();
    setResetPasswordStatus({
      success: false,
      errorMessage: null,
    });
    apiService
      .post(`${constants.envConstants.BASE_API_URL}/user/forgot-password`, {
        email: emailValue,
      })
      .then((result) => {
        setResetPasswordStatus({
          attempted: true,
          success: true,
          errorMessage: null,
        });
      })
      .catch((error) => {
        setResetPasswordStatus({
          attempted: true,
          success: false,
          errorMessage: apiService.responseHandler.describeError(error),
        });
      });
  };

  return AlpsLayoutContainer(
    <div>
      <div className="text u-spacing u-space--top 1-grid">
        <img src={logo} alt="Cape Conference Logo" className="loginLogo" width="250" />
        <form onSubmit={resendEmail} style={{ width: "100%" }}>
          <h2 className="u-font--secondary--l u-theme--color--darker">
            Forgot Password?
          </h2>
          <br />
          {!resetPasswordStatus.success && (
            <>
              <label>
              {constants.messages.ENTER_YOUR_EMAIL}. {constants.messages.INSTRUCTIONS_WILL_BE_SENT}
                <input
                  type="email"
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                  required
                />
              </label>
              <br />
              <br />
              {resetPasswordStatus.errorMessage && (
                <>
                  <div className="alert alert-danger" role="alert">
                    {resetPasswordStatus.errorMessage}
                  </div>
                  <br />
                </>
              )}

              <div className="u-flex u-flex--align-center">
                <button type="submit" className="o-button">
                  Resend{" "}
                </button>{" "}
                &nbsp;{" "}
                <Link to="/logout" className="o-button o-button--outline">
                  Cancel
                </Link>
              </div>
            </>
          )}
          {resetPasswordStatus.success && (
            <>
              <div className="alert alert-success" role="alert">
                {constants.messages.RESEND_FORGOT_SUCCESS}
              </div>
              <br />
              <Link to="/logout" className="o-button">
                Go to Login <ArrowRight />
              </Link>
              <br />
              <br />
              <br />
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
