import constants from "../../constants";

export function showModal(content, title = null) {
  return (dispatch) => {
    return dispatch({
      type: constants.reduxActions.MODAL_OPEN,
      content,
      title
    });
  };
}

export function closeModal() {
  return (dispatch) => {
    return dispatch({
      type: constants.reduxActions.MODAL_CLOSE,
    });
  };
}


const modalActions = {
    showModal,
    closeModal
}

export default modalActions;