export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const UPDATE_CURRENT_USER_PROFILE = "UPDATE_CURRENT_USER_PROFILE";
export const LOAD_ALL_USERS_SUCCESS = "LOAD_ALL_USERS_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const BATCH_DELETE_USER_SUCCESS = "BATCH_DELETE_USER_SUCCESS";
export const BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const UNBLOCK_USER_SUCCESS = "UNBLOCK_USER_SUCCESS";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";
export const LOAD_ENTITIES_SUCCESS = "LOAD_ENTITIES_SUCCESS";
export const DELETE_ENTITY_SUCCESS = "DELETE_ENTITY_SUCCESS";
export const BATCH_DELETE_ENTITY_SUCCESS = "BATCH_DELETE_ENTITY_SUCCESS";
export const LOAD_ENTITIES_FAILURE = "LOAD_ENTITIES_FAILURE";
export const LOAD_RECORDS_SUCCESS = "LOAD_RECORDS_SUCCESS";
export const LOAD_DUPLICATES_SUCCESS = "LOAD_DUPLICATES_SUCCESS";
export const DELETE_RECORD_SUCCESS = "DELETE_RECORD_SUCCESS";
export const BATCH_DELETE_RECORD_SUCCESS = "BATCH_DELETE_RECORD_SUCCESS";
export const LOAD_RECORDS_FAILURE = "LOAD_RECORDS_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";
export const API_REQUEST_IN_PROGRESS = "API_REQUEST_IN_PROGRESS";
export const API_REQUEST_FAILURE = "API_REQUEST_FAILURE";
export const API_REQUEST_SUCCESS = "API_REQUEST_SUCCESS";
export const API_REQUEST_CLEAR_ERROR = "API_REQUEST_CLEAR_ERROR";
export const MODAL_CLOSE = "MODAL_CLOSE";
export const MODAL_OPEN = "MODAL_OPEN";