import Aside from "./Aside";
import Footer from "./Footer";

const AlpsLayoutContainer = (component) => {
  return (
    <div className="l-wrap">
      <div className="l-wrap__content l-content" role="document">
        <main
          className="l-main u-spacing--double u-padding--double--bottom"
          role="main"
        >
          <section className="l-grid l-grid--7-col u-shift--left--1-col--at-large l-grid-wrap--6-of-7 u-spacing--double--until-xxlarge u-padding--zero--sides">
            <div className="c-article l-grid-item l-grid-item--l--4-col ">
              <article className="c-article__body">
                <div className="text u-spacing">{component}</div>{" "}
              </article>
            </div>
          </section>
        </main>
        <Footer />
      </div>
      <Aside />
    </div>
  );
};

export default AlpsLayoutContainer;
