import { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import logo from "../../assets/logo.svg";
import ArrowRight from "../shared/ArrowRight";
import LoadingIndicator from "../shared/LoadingIndicator";

import "./LoginPage.css";
import userActions from "../../redux/actions/userActions";
import AlpsLayoutContainer from "../shared/AlpsLayoutContainer";
import GoogleSignInButton from "../shared/google-sign-in-button/GoogleSignInButton";

const LoginPage = (props) => {
  const { isWorking } = props.apiStatus;
  const { authErrorMessage } = props.userStatus;
  const [usernameValue, setUsernameValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isWorking) {
      return false;
    }
    props.actions.loginUser({
      email: usernameValue,
      password: passwordValue,
    });
  };

  const handleGoogleLogin = (token) =>{  
    if (isWorking) {
      return false;
    }
    props.actions.loginUser({
      token
    });

  }

  return props.userStatus.loggedIn ? (
    <Redirect to="/home" />
  ) : (
    AlpsLayoutContainer(
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <div className="u-padding--triple--top text u-spacing">
          <img src={logo} alt="Cape Conference Logo" className="loginLogo" width="250" height="90"/>
          <br />
          <label>
            Username*
            <input
              type="text"
              value={usernameValue}
              onChange={(e) => setUsernameValue(e.target.value)}
              required
              disabled={isWorking}
            />
          </label>
         
          <label>
            Password*
            <input
              type="password"
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              required
              disabled={isWorking}
            />
          </label>
          <br />
          <div className="u-flex u-flex--align-center">
            <button type="submit" className="o-button" disabled={isWorking}>
              Login{" "}
            </button>
            <Link to="forgot" className="o-button o-button--simple">
              Forgot Password?
            </Link>
          </div>
          <br/>
          {isWorking && <LoadingIndicator fullscreen={true} />}
          {!isWorking && authErrorMessage && (
            <div className="alert alert-danger" role="alert">
              {`${authErrorMessage
                .charAt(0)
                .toUpperCase()}${authErrorMessage.slice(1)}`}
            </div>
          )}

          <hr />
          <div className="centerContents">
            ...or{"   "}
                <GoogleSignInButton onGoogleLoginCallback={handleGoogleLogin} disabled={isWorking} />
          </div>
          <hr />
          <span className="u-color--information">No Account?&nbsp;</span>
          <Link to="signup" className="o-button o-button--simple">
            &nbsp;Create New Account
            <ArrowRight />
          </Link>
        </div>
      </form>
    )
  );
};

const mapStateToProps = (state) => {
  return { userStatus: state.user, apiStatus: state.api };
};

const mapDispathToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispathToProps)(LoginPage);
